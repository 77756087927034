<!--
 * @Author: your name
 * @Date: 2020-12-15 16:03:49
 * @LastEditTime: 2021-12-01 17:00:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\salesBusiness\marketInforPrice\maintainPop.vue
-->
<template>
    <div class="marketInforPrice-maintainPop" v-loading="loading">
        <div class="head_box_container">
            <div class="fl">
                <span class="title">
                    <i class="el-icon-s-order"></i>
                    基本信息
                </span>
                <div class="side1"></div>
                <div class="side2"></div>
                <div class="side3"></div>
            </div>
        </div>
        <div class="main-table-box">
            <table class="main-table">
                <!-- <t_box>
                    <tr>
                        <th colspan="4" class="main-table-tit">
                            基本信息
                        </th>
                    </tr>
                </t_box> -->
                <tbody>
                    <tr>
                        <td class="row-tit">
                            信息价发布地区：
                        </td>
                        <td>
                            <span>{{ periodData.period.region_name }}</span>
                        </td>
                        <td class="row-tit">
                            维护时间：
                        </td>
                        <td>
                            <span>{{ periodData.period.created }}</span>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="8" class="tr_border_th">
                            <div class="tr_border"></div>
                        </th>
                    </tr>
                    <tr>
                        <td class="row-tit">
                            更新频率：
                        </td>
                        <td>
                            <span>{{ periodData.period.update_frequency }}</span>
                        </td>
                        <td class="row-tit">
                            更新日期：
                        </td>
                        <td>
                            <span>{{ periodData.period.update_date }}</span>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="8" class="tr_border_th">
                            <div class="tr_border"></div>
                        </th>
                    </tr>
                    <tr>
                        <td class="row-tit">
                            累计期次：
                        </td>
                        <td @click="viewCumulativePeriods" style="cursor: pointer;">
                            <span :class="{'linkClick': isReadOnly == false}">{{ periodData.period.current_period_total }}</span>
                        </td>
                        <td class="row-tit">
                            合同数量：
                        </td>
                        <td @click="openContract" style="cursor: pointer;">
                            <span :class="{'linkClick': isReadOnly == false}">
                                {{ periodData.period.contract_quantity }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="8" class="tr_border_th">
                            <div class="tr_border"></div>
                        </th>
                    </tr>
                    <tr>
                        <td class="row-tit">
                            维护期次：
                        </td>
                        <td>
                            <span>{{ periodData.period.current_period }}</span>
                        </td>
                        <td class="row-tit">
                            期次月份：
                        </td>
                        <td>
                            <span>{{ periodData.period.period_month }}</span>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="8" class="tr_border_th">
                            <div class="tr_border"></div>
                        </th>
                    </tr>
                    <tr>
                        <td class="row-tit">
                            维护人：
                        </td>
                        <td>
                            <span>{{ periodData.period.maintainer }}</span>
                        </td>
                        <td class="row-tit">
                            审核人：
                        </td>
                        <td>
                            <span>{{ periodData.period.reviewer }}</span>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="8" class="tr_border_th">
                            <div class="tr_border"></div>
                        </th>
                    </tr>
                    <tr>
                        <td class="row-tit">
                            状态：
                        </td>
                        <td colspan="3">
                            <span v-if="periodData.period.state == 1">已保存</span>
                            <span v-if="periodData.period.state == 2">已提交</span>
                            <span v-if="periodData.period.state == 3">已确认</span>
                            <span v-if="periodData.period.state == 4">已退回</span>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td colspan="4" class="split-row">
                            <span class="fl">市场价格信息</span>
                            <div class="fr">
                                <span
                                    class="table-btn readpre"
                                    @click="readPreviousIssue"
                                    v-if="isNewlyAdded && isApproval == false && isReadOnly == false"
                                >
                                    读取上期
                                </span>
                                <span
                                    class="table-btn chose"
                                    @click="selectMarketInfoPrice"
                                    v-if="isApproval == false && isReadOnly == false && (isDisabled == false || isNewlyAdded)"
                                >选择</span>
                            </div>
                        </td>
                    </tr> -->
                </tbody>
            </table>
        </div>
        <div class="head_box_container">
            <div class="fl">
                <span class="title">
                    <i class="el-icon-s-order"></i>
                    市场价格信息
                </span>
                <div class="side1"></div>
                <div class="side2"></div>
                <div class="side3"></div>
            </div>
            <div class="fr">
                <span
                    class="table-btn readpre"
                    @click="readPreviousIssue"
                    v-if="isNewlyAdded && isApproval == false && isReadOnly == false"
                >
                    读取上期
                </span>
                <span
                    class="table-btn chose"
                    @click="selectMarketInfoPrice"
                    v-if="isApproval == false && isReadOnly == false && (isDisabled == false || isNewlyAdded)"
                >选择</span>
            </div>
        </div>
        <el-table
            :data="periodData.periodExtendList"
            style="width: 100%"
            height="calc(100% - 4.4rem)"
            :cell-style="tableCellStyle"
        >
            <el-table-column
                type="index"
                width="55"
                label="序号"
            >
            </el-table-column>
            <el-table-column
                prop="info_price_number"
                label="编码"
                width="190"
            >
            </el-table-column>
            <el-table-column
                prop="info_price_name"
                label="产品名称"
                width="160"
            >
            </el-table-column>
            <el-table-column label="规格型号">
                <el-table-column
                    prop="specification_mode_name"
                    label="名称"
                >
                    <div slot="header" class="el_table_header">
                        名称
                    </div>
                </el-table-column>
                <el-table-column
                    prop="product_mark_num"
                    label="产品标号"
                >
                    <div slot="header" class="el_table_header">
                        产品标号
                    </div>
                </el-table-column>
                <el-table-column
                    prop="additional_model"
                    label="附加型号"
                >
                    <div slot="header" class="el_table_header">
                        附加型号
                    </div>
                </el-table-column>
                <el-table-column
                    prop="coarse_aggregate"
                    label="粗骨料"
                >
                    <div slot="header" class="el_table_header">
                        粗骨料
                    </div>
                </el-table-column>
                <el-table-column
                    prop="fine_aggregate"
                    label="细骨料"
                >
                    <div slot="header" class="el_table_header">
                        细骨料
                    </div>
                </el-table-column>
                <el-table-column
                    prop="pour_mode"
                    label="浇筑方式"
                >
                    <div slot="header" class="el_table_header">
                        浇筑方式
                    </div>
                </el-table-column>
            </el-table-column>
            <el-table-column
                prop="measuring_unit"
                label="单位"
            >
            </el-table-column>
            <el-table-column
                label="含税单价"
            >
                <template slot-scope="scope">
                    <div>
                        <el-input
                            v-model="scope.row.including_tax_price"
                            size="mini"
                            @input="onInput"
                            @change="priceEditing(scope.row, scope.$index)"
                            :disabled="isDisabled"
                        ></el-input>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="税率"
            >
                <template slot-scope="scope">
                    <el-input
                        v-model="scope.row.tax_rate"
                        size="mini"
                        @input="onInput"
                        @change="priceEditing(scope.row, scope.$index)"
                        :disabled="isDisabled"
                    ></el-input>
                </template>
            </el-table-column>
            <el-table-column
                label="除税单价(元)"
            >
                <template slot-scope="scope">
                    <el-input
                        v-model="scope.row.tax_free_price"
                        size="mini"
                        @input="onInput"
                        @change="priceEditing(scope.row, scope.$index)"
                        :disabled="isDisabled"
                    ></el-input>
                </template>
            </el-table-column>
        </el-table>
        <div class="btn-con">
            <div class="" v-if="isApproval == false && (isRevisedCurrentIssue || isNewNextPeriod)">
                <el-button
                    type="primary"
                    @click="revisedCurrentIssue"
                    plain
                    v-if="isReadOnly == false && isRevisedCurrentIssue && btnType !== 'edit'"
                >
                    修订本期
                </el-button>
                <el-button
                    type="primary"
                    @click="newNextPeriod"
                    plain
                    v-if="isReadOnly == false && isNewNextPeriod && btnType !== 'add'"
                >
                    新增下期
                </el-button>
            </div>
            <template v-if="isReadOnly == false">
                <template v-if="isApproval == false">
                    <el-button
                        type="primary"
                        @click="submit"
                        plain
                        v-if="isSubmitBtn"
                    >
                        提交
                    </el-button>
                    <el-button
                        type="primary"
                        @click="preservation"
                        plain
                        v-if="isPreservation"
                    >
                        保存
                    </el-button>
                </template>
                <template v-else>
                    <el-button
                        type="primary"
                        @click="adopt"
                        plain
                    >
                        确认
                    </el-button>
                    <el-button
                        type="primary"
                        @click="goBack"
                        plain
                    >
                        退回
                    </el-button>
                </template>
            </template>
            <el-button
                type="primary"
                @click="close"
                plain
            >
                关闭
            </el-button>
        </div>
    </div>
</template>

<script>
import NP from 'number-precision';
export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            btnType: '',
            loading: false,
            info_price_period_number: '',
            // 期次数据
            periodData: {
                period: {
                    info_price_period_number: '',
                    info_price_regional_number: '',
                    region_code: '',
                    region_name: '',
                    update_frequency: '',
                    update_frequency_code: '',
                    update_date: 0,
                    current_period: '',
                    current_period_year: 0,
                    current_period_month: 0,
                    period_month: 0,
                    period_year: 0,
                    state: 0,
                    maintainer: '',
                    reviewer: '',
                    created: '',
                    current_period_total: 0,
                    current_period_begin_date: '',
                    current_period_end_date: '',
                    contract_quantity: 0,
                },
                periodExtendList: [],
            },
            // 是否只读(没有点修订本期，当前期次只读不能修改)
            isDisabled: true,
            // 是否是查看场景（查看场景，不显示按钮）
            isReadOnly: false,
            // 是否是新增
            isNewlyAdded: false,
            // 是否是审批（查看场景，显示确认和退回按钮）
            isApproval: false,
            // 是否显示提交按钮
            isSubmitBtn: false,
            // 是否显示保存按钮
            isPreservation: false,
            // 是否可新增下期
            isNewNextPeriod: true,
            // 是否可修订本期
            isRevisedCurrentIssue: true,
        };
    },
    watch: {},
    computed: {},
    created() {
        this.info_price_period_number = this.extr.row.info_price_period_number;
        // 获取期次数据
        this.getPeriodData();
        // this.extr.isApproval审批（查看场景，显示确认和退回按钮）
        if (this.extr && this.extr.isApproval) {
            this.isApproval = true;
        }
        // this.extr.isReadOnly查看（查看场景，不显示按钮）
        if (this.extr && this.extr.isReadOnly) {
            this.isReadOnly = true;
        }
    },
    mounted() {},
    methods: {
        tableCellStyle({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 10 || columnIndex === 11 || columnIndex === 12) {
                return 'background: #F5F7FA';
            }
        },
        // 获取期次数据
        getPeriodData() {
            this.loading = true;
            this.$axios
                .get(`/interfaceApi/sale/info_price/get_period_by_number?periodNumber=${this.info_price_period_number}`)
                .then(res => {
                    if (res.period) {
                        this.periodData.period = res.period;
                        console.log(res.period, this.extr, 'res.period');

                        if (this.periodData.period.state === 1 || this.periodData.period.state === 4) {
                            // 是否显示提交按钮
                            this.isSubmitBtn = true;
                        } else if (this.periodData.period.state === 2) {
                            // 是否可修订本期
                            this.isRevisedCurrentIssue = false;
                        }
                        if (this.periodData.period.state !== 3) {
                            // 是否可新增下期
                            this.isNewNextPeriod = false;
                        }
                    } else {
                        this.isRevisedCurrentIssue = false;
                    }
                    if (res.periodExtendList) {
                        this.periodData.periodExtendList = res.periodExtendList;
                    }
                    this.isNewlyAdded = false;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 新增下期
        newNextPeriod() {
            this.$axios
                .get(`/interfaceApi/sale/info_price/get_next_period_by_number?regionalNumber=${this.extr.row.info_price_regional_number}`)
                .then(res => {
                    this.periodData.period = res;
                    this.periodData.periodExtendList = [];
                    this.isDisabled = false;
                    this.isNewlyAdded = true;
                    this.isPreservation = true;
                    this.btnType = 'add';
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 读取上期
        readPreviousIssue() {
            this.$axios
                .get(`/interfaceApi/sale/info_price/get_previous_period_extend?regionalNumber=${this.extr.row.info_price_regional_number}`)
                .then(res => {
                    if (!res) {
                        this.$message('未读取到上期数据！');
                        return;
                    }
                    this.periodData.periodExtendList = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 修订本期
        revisedCurrentIssue() {
            if (this.isNewlyAdded) {
                // 获取期次数据
                this.getPeriodData();
            }
            this.btnType = 'edit';
            this.isDisabled = false;
            this.isPreservation = true;
        },
        // 选择市场信息价
        selectMarketInfoPrice() {
            this.$toast({
                title: true,
                text: '信息价产品',
                type: 'eject',
                width: '15rem',
                height: '80%',
                t_url: 'salesBusiness/marketInforPrice/chooseInformationPrice',
                viewPosition: 'view',
                extr: {
                    regionalNumber: this.extr.row.info_price_regional_number,
                    selectCallBack: this.selectMarketInfoPriceCallBack,
                },
            });
        },
        // 选择市场信息价回调
        selectMarketInfoPriceCallBack(data) {
            const reArr = [];
            data.forEach(k => {
                this.periodData.periodExtendList.forEach(item => {
                    if (item.compose_code === k.compose_code) {
                        reArr.push(k);
                    }
                });
            });
            const row = data.filter(item => {
                return !reArr.find(k => item.Id === k.Id);
            });
            if (reArr.length > 0) {
                let text = '';
                reArr.forEach(item => {
                    text += item.specification_mode_name + ' ';
                });
                this.$message({
                    message: `${text}已存在！ `,
                    type: 'warning',
                });
            }
            if (row.length > 0) {
                if (this.isNewlyAdded === false) {
                    // 是否只读
                    this.isDisabled = false;
                }
                this.periodData.periodExtendList.push(...row);
            }
        },
        // 保存
        preservation() {
            if (this.isNewlyAdded) {
                this.periodData.period.state = 1;
                this.saveRequest('preservation');
            } else if (this.isDisabled === false) {
                this.periodData.period.state = 1;
                this.modificationRequest('preservation');
            } else {
                this.$message({
                    message: '请先修改或者新增！',
                    type: 'warning',
                });
            }
        },
        // 提交
        submit() {
            if (this.isDisabled && this.isNewlyAdded === false) {
                if (this.periodData.period.state !== 3) {
                    const requestData = {
                        periodNumber: this.info_price_period_number,
                        operationType: 2,
                    };
                    this.updateStatusRequest(requestData, '已提交！');
                }
            } else if (this.isNewlyAdded) {
                this.periodData.period.state = 2;
                this.saveRequest('submit');
            } else if (this.isDisabled === false) {
                this.periodData.period.state = 2;
                this.modificationRequest('submit');
            }


        },
        // 通过
        adopt() {
            const requestData = {
                periodNumber: this.info_price_period_number,
                operationType: 3,
            };
            this.updateStatusRequest(requestData, '已确认！');
        },
        // 退回
        goBack() {
            const requestData = {
                periodNumber: this.info_price_period_number,
                operationType: 4,
            };
            this.updateStatusRequest(requestData, '已退回！');
        },
        // 累计期次
        viewCumulativePeriods() {
            if (this.isReadOnly === false) {
                this.$toast({
                    title: true,
                    text: '查看累计期次',
                    type: 'eject',
                    width: '15rem',
                    height: '75%',
                    t_url: 'salesBusiness/marketInforPrice/showAggregatePeriod',
                    extr: {
                        tips: '查看累计期次',
                        regionalNumber: this.extr.row.info_price_regional_number,
                        periodNumber: this.info_price_period_number,
                    },
                });
                // this.$toast({
                //     title: true,
                //     type: 'eject',
                //     width: '70%',
                //     t_url: 'tableList/index',
                //     extr: {
                //         code: {
                //             TableCode: 'xxj-ljqc-tc',
                //             QueryString: `regional_number=${this.extr.row.info_price_regional_number}`,
                //         },
                //     },
                // });
            }
        },
        // 查看合同
        openContract() {
            if (this.isReadOnly === false) {
                this.$toast({
                    title: true,
                    type: 'eject',
                    width: '70%',
                    t_url: 'tableList/index',
                    extr: {
                        code: {
                            TableCode: 'xxj-htlb',
                            QueryString: `period_number=${this.info_price_period_number}`,
                        },
                    },
                });
            }
        },

        // 更新状态请求
        updateStatusRequest(requestData, text) {
            this.$axios
                .put('/interfaceApi/sale/info_price/update_period_status', requestData)
                .then(() => {
                    this.$message({
                        message: text,
                        type: 'success',
                    });
                    this.$parent.hide();
                    this.extr.table.getTableList();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存请求
        saveRequest(type) {
            this.$axios
                .post('/interfaceApi/sale/info_price/add_period_info', this.periodData)
                .then(res => {
                    this.info_price_period_number = res.period.info_price_period_number;
                    let text = '';
                    if (type === 'preservation') {
                        text = '保存成功！';
                        this.isSubmitBtn = true;
                        this.periodData = res;
                    } else if (type === 'submit') {
                        text = '提交成功！';
                        this.$parent.hide();
                    }
                    if (res.period.state === 1) {
                        this.isNewNextPeriod = false;
                    }
                    this.extr.table.getTableList();
                    this.$message({
                        message: text,
                        type: 'success',
                    });
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 修改请求
        modificationRequest(type) {
            this.$axios
                .put('/interfaceApi/sale/info_price/edit_period_info', this.periodData)
                .then(res => {
                    let text = '';
                    if (type === 'preservation') {
                        text = '修改成功！';
                        this.isSubmitBtn = true;
                    } else if (type === 'submit') {
                        text = '提交成功！';
                        this.$parent.hide();
                    }

                    this.extr.table.getTableList();
                    this.$message({
                        message: text,
                        type: 'success',
                    });
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 关闭
        close() {
            this.$parent.hide();
        },
        onInput() {
            this.$forceUpdate();
        },

        // 价格编辑
        priceEditing(item, index) {
            const res = JSON.parse(JSON.stringify(item));
            if (!res.including_tax_price && res.tax_rate && res.tax_free_price) {
                // 含税单价 = 除税单价 *（1 + 税率）
                res.including_tax_price = NP.times(res.tax_free_price, NP.plus(1, NP.divide(res.tax_rate, 100))).toFixed(2);
            } else if (!res.tax_free_price && res.including_tax_price && res.tax_rate) {
                // 除税单价 = 含税单价 / (1 + 税率）
                res.tax_free_price = NP.divide(res.including_tax_price, NP.plus(1, NP.divide(res.tax_rate, 100))).toFixed(2);
            }
            this.$set(this.periodData.periodExtendList, index, res);
        },
    },
};
</script>
<style lang="stylus" scoped>
    >>>.el-input__inner
        border none !important
    .head_box_container
        display flex
        border-bottom 1.5px solid #0062B5
        margin .1rem 0
        font-size 0.14rem
        font-family AliHYAiHei
        color #ffffff
        div
            display flex
            justify-content center
            align-items center
            div
                height 100%
                background #006EFF
                transform skew(15deg)
                margin 0 .05rem
            .title
                display block
                padding .05rem .2rem .05rem .05rem
                background #0062B5
            .side1
                width 0.25rem
                margin-left -.2rem
                background #0062B5 !important
            .side2
                width .1rem
                opacity .2
            .side3
                width .1rem
                opacity .06
        .fr
            position absolute
            right .3rem
            .table-btn
                padding .05rem .15rem
                background #1577D2
                border-radius .04rem
                color #fff
                margin-right .2rem
                font-size .14rem
                height .28rem
            span
                color #022782
    .marketInforPrice-maintainPop
        height 100%
        padding .14rem
        .el_table_header
            margin-top -.02rem
            background #F7FAFD !important
        .el-table thead.is-group th.el-table__cell
            background #EDF0F5
        .btns
            height 0.32rem
            margin-bottom 0.13rem
            .el-button
                width 0.9rem
                height 0.32rem
                line-height 0.3rem
                padding 0
        .main-table-box
            border 1px solid #D7D7D7
        .main-table
            width 100%
            border-collapse collapse
            // border 1px solid #D7D7D7
            th,td
                // border 1px solid #D7D7D7
                // line-height .38rem
                font-size .16rem
                padding .01rem
                span
                    display inline-block
                    width 100%
                    height .38rem
                    margin-bottom -.06rem
                    line-height .38rem
                    border 1px solid #D7D7D7
                    padding-left .06rem
                .el-select
                    width 100%
                    .el-input__inner
                        border none
                        text-indent 0
                .linkClick
                    color blue
                    text-decoration underline
            .main-table-tit
                background #EDF0F5
                font-size .18rem
                color #022782
                font-weight normal
            .row-tit
                background #F4F4F4
                border 1px solid #fff
                color #022782
                width 1.6rem
                text-align right
            .split-row
                background #EDF0F5
                line-height .40rem
                // display flex
                .table-btn
                    padding .05rem .15rem
                    background #1577D2
                    border-radius .04rem
                    color #fff
                    margin-right .2rem
                    font-size .14rem
                span
                    color #022782
            .fl
                float left
        .el-table--border
            th
                border-right 1px solid #D7D7D7
                border-bottom 1px solid #D7D7D7
            td
                border-right 1px solid #D7D7D7
        .el-table
            th,td
                padding 0
                text-align center
            th
                &.is-leaf
                    border-bottom 1px solid #D7D7D7
            td
                border-bottom 1px solid #D7D7D7
            &::before
                background-color #d7d7d7
            t_box
                &.is-group
                    th
                        background #F6F8FC
                        color #022782
                        font-weight normal
                        font-size .16rem
            .cell
                padding 0 !important
            .el-input.is-disabled .el-input__inner
                border none
        .el-table--border, .el-table--group
            border 1px solid #D7D7D7
            border-right none
            border-bottom none
            &::after
                background-color #d7d7d7
        .btn-con
            position absolute
            bottom .2rem
            left 0
            width 100%
            display flex
            justify-content center
            div
                margin-right .12rem
                display flex
            .el-button
                width 1.6rem
                height .40rem
                line-height .1rem
                display flex
                justify-content center
                align-items center
            // .btn-item
            //     width 2.20rem
            //     height .40rem
            //     line-height .4rem
            //     background #1577D2
            //     border-radius 1px
            //     color #fff
            //     display inline-block
            //     margin 0 .3rem
            //     cursor pointer
            // .close-btn
            //     border 1px solid #1577D2
            //     background #ffffff
            //     color #1577D2
</style>
